import { endpoints } from "@app/config/endpoints";
import { ajax } from "@app/lib/ajax";
import { deleteData, saveData } from "@app/lib/api-utils";
import { BrandStageStatus, BrandStatus } from "@app/config/enums";
import { defaultPackage } from "@app/data/packages";
import { DEFAULT_STAGE, DEFAULT_TYPE } from "@app/data/brand-stages";
import moment from "moment";
import { sessionStore } from "@app/stores/SessionStore";

const url = endpoints.brand;
export const saveBrand = (brand: IBrand) => saveData({ url }, brand);

export const deleteBrand = (brand: IBrand | number) => deleteData(url, brand);

export const refineBrandFromApi = (d: IBrand) => {
    return d;
};

export const getBrandById = async (id: number | string): Promise<IBrand> => {
    const resp = await ajax.get({
        url: `${url}/${id}`,
        params: { with: ["roles"] },
    });
    return refineBrandFromApi(resp.data);
};

export function terminalsUrl(id: number | string): string {
    return `/brand/${id}/terminals`;
}

export function terminalsApiUrl(id: string | number): string {
    return endpoints.brandTerminals.replace(":id", id.toString());
}

export function brandLoginApiUrl(id: string | number): string {
    return endpoints.brandLogin.replace(":id", id.toString());
}

export function getDefaultCountry(): string {
    return sessionStore.user.partner?.countries[0] ?? "AZ";
}

export function dummyBrand(brand?: IBrand): IBrand {
    return {
        name: "",
        title: "",
        client_id: undefined,
        client: {},
        country: getDefaultCountry(),
        stage: DEFAULT_STAGE,
        stage_status: BrandStageStatus.TRIAL,
        type: DEFAULT_TYPE,
        deleted_at: Date.now().toString(),
        // client_id: 0,
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        status: BrandStatus.CREATING,
        subscription: dummySubscription(),
        discountable: true,
        partner_id: sessionStore.user.partner?.id,
        ...brand,
    } as IBrand;
}

export function dummySubscription(
    subscription?: ISubscription,
    pack?: IPackage
): ISubscription {
    pack = pack || defaultPackage();
    const country = getDefaultCountry();
    const countryPrices = pack.prices?.find((p) => p.country_code === country);
    const expiration = moment().add(7, "day").format("YYYY-MM-DD HH:mm:ss");
    return {
        monthly_period: 1,
        trial: false,
        packages: [pack],
        period_price: countryPrices?.price ?? pack.price,
        package_id: pack.id,
        currency: countryPrices?.currency,
        expired_at: expiration,
        subscribed_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        created_at: Date.now().toString(),
        updated_at: Date.now().toString(),
        ...subscription,
    } as ISubscription;
}

export function getPackage(brand: IBrand): IPackage {
    const { packages } = getSubscription(brand);
    if (!packages) {
        return {} as any;
    }
    return packages.find((p) => p.type === "PACKAGE")!;
}

export function getSubscription(brand: IBrand): ISubscription {
    if (!brand.subscription) {
        throw new Error("Brand doesn't have any subscriptions");
    }
    return brand.subscription;
}
